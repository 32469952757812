import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "./contact.yml"
import Icon from '../images/pcx-avocats.svg'
import OuiAvocats from '../images/oui-avocats.png'
import AvantPoste from '../images/contact.jpg'

const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const SendForm = e => {
  e.stopPropagation()
  e.preventDefault()
  
  let email = document.querySelector('input[name=email]');
  let subject = document.querySelector('input[name=subject]');
  let message = document.querySelector('textarea[name=message]');

  email.style.borderColor = 'black';

  if(!validateEmail(email.value)) {
    email.style.borderColor = 'red';
  } else {
    let values = {email: email.value, subject: subject.value, message: message.value};
    fetch('/scripts/email.php', { method: 'POST', body: JSON.stringify(values)}).then(data => {
        document.querySelector('form').innerHTML = '<div class="success">Merci pour votre message. Nous vous contactons dès que possible.</div>';
    });
  } 
}

const ContactPage = () => {
  setTimeout(() => {
    
    document
    .querySelector("#menu")
    .querySelectorAll("li").forEach(li => {
      li.classList.remove('current')
    })
    
    document
    .querySelector("#menu")
    .querySelectorAll("li")[3]
    .classList.add("current");
  }, 2000)

  return (
    <Layout>
      <SEO title={Content.title} />
      <div className="content contact">
        <div className="icon"><Icon/></div>
        <div className="container">
          <div className="contact-info">
            <p dangerouslySetInnerHTML={{__html: Content.content.infos[0]}}></p>
            <p dangerouslySetInnerHTML={{__html: Content.content.infos[1]}}></p>
          </div>
          <div className="form">
            <form>
              <label>{Content.content.form.email}<input type="email" name="email"/></label>
              <label>{Content.content.form.subject} <input type="text" name="subject"/></label>
              <label>{Content.content.form.message} <textarea className="" name="message"></textarea></label>
              <button onClick={SendForm}>{Content.content.form.button}</button>
            </form>
          </div>
        </div>

        <div className="gmap">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10120.644906786229!2d3.0636454!3d50.6426968!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd3dda85291ac26f3!2sPCX+AVOCATS!5e0!3m2!1sfr!2sfr!4v1531229467503" height="350" width="100%">
              </iframe>
        </div>

        <div className="oui-avocats">
          <h2 style={{textAlign: `center`, marginTop: `50px`, marginBottom: `50px`}} dangerouslySetInnerHTML={{__html: Content.content.oui_avocats}}></h2>
          <p><a href="https://www.oui-avocats.fr/" target="_blank" rel="noopener noreferrer"><img alt="Oui Avocats" style={{width:`40%` ,display:`block`,margin: `auto`}} src={OuiAvocats}/></a></p>
        </div>

        <div className="hebergement" dangerouslySetInnerHTML={{__html: Content.content.hebergement}}></div>
        <div className="hebergement" dangerouslySetInnerHTML={{__html: Content.content.rgpd}}></div>
        <div className="conception" dangerouslySetInnerHTML={{__html: Content.content.conception}}></div>
      </div>
    </Layout>
  )
}


export default ContactPage
